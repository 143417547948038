"use client";

import { BigFloat } from "shared/src/utils/BigFloat";
import { SupportedLoanCurrencyId } from "shared/src/SupportedCurrencies";
import { useEffect, useState } from "react";
import { getSerializedRates } from "./actions";
import pino from "pino";

const logger = pino({ name: "Conversion Hooks" });

const refreshRate = 500;

export const staleRate = 0.2 * 60000;

export type LoanCurrencyRatesCollection = {
  assetId: SupportedLoanCurrencyId;
  rate: BigFloat;
}[];

export function useRates(initialRates: LoanCurrencyRatesCollection) {
  const [rates, setRates] = useState(initialRates);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const rates = await getSerializedRates(
          initialRates.map((asset) => asset.assetId)
        );
        setRates(
          BigFloat.deepDeserialize(rates) as LoanCurrencyRatesCollection
        );
      } catch (err: unknown) {
        logger.error({ err }, "Error getting rates");
      }
    }, refreshRate);

    return () => clearInterval(interval);
  }, []);

  return rates;
}
