import { SupportedLoanCurrencyId } from "shared/src/SupportedCurrencies";
import { BigFloat } from "shared/src/utils/BigFloat";
import { formatCurrency } from "src/formatting/functions";

export function LoanAmountSlider(props: {
  loanAmount: string;
  loanCurrency: SupportedLoanCurrencyId;
  minLoanInLoanCurrency: BigFloat;
  maxLoanInLoanCurrency: BigFloat;
  sliderPos: number;
  sliderClassName: string;
  catchErrors: () => void;
  setLoanAmount: (amount: string) => void;
}) {
  const {
    loanAmount,
    loanCurrency,
    maxLoanInLoanCurrency,
    minLoanInLoanCurrency,
    sliderClassName,
    sliderPos,
    catchErrors,
    setLoanAmount,
  } = props;

  return (
    <div
      id="loan-amount-slider-container"
      className="relative mt-2 flex flex-col gap-2"
    >
      <div id="labels" className="flex flex-row justify-between">
        <label className="flex flex-row gap-1 font-sans text-[0.6rem] text-text">
          min{" "}
          <p className=" font-bold">
            {" "}
            {formatCurrency(
              loanCurrency,
              minLoanInLoanCurrency.formatAndRound(0).toLocaleString("no-NO")
            )}
          </p>
        </label>
        <label className="flex flex-row gap-1 font-sans text-[0.6rem] text-text">
          max{" "}
          <p className=" font-bold">
            {" "}
            {formatCurrency(
              loanCurrency,
              maxLoanInLoanCurrency.formatAndRound(0).toLocaleString("no-NO")
            )}
          </p>
        </label>
      </div>
      <input
        type="range"
        value={
          loanAmount === ""
            ? 0
            : loanAmount.endsWith(".")
            ? loanAmount + "0"
            : loanAmount
        }
        onMouseUp={() => catchErrors()}
        onChange={(e) => {
          setLoanAmount(e.target.value);
        }}
        min={minLoanInLoanCurrency.formatAndRound()}
        max={maxLoanInLoanCurrency.formatAndRound()}
        step={0.1}
        className="h-[0.625rem] w-full appearance-none rounded-lg bg-gray-300 outline-none 
      [&::-moz-range-thumb]:h-5 [&::-moz-range-thumb]:w-5 [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:border-transparent 
    [&::-moz-range-thumb]:bg-calculator-slider [&::-moz-range-thumb]:hover:cursor-pointer 
      [&::-webkit-slider-thumb]:h-5 [&::-webkit-slider-thumb]:w-5 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:rounded-full 
    [&::-webkit-slider-thumb]:bg-calculator-slider [&::-webkit-slider-thumb]:hover:cursor-pointer"
      />
      <div className="pointer-events-none absolute bottom-0 w-full">
        <div
          style={{
            width: sliderPos + "%",
          }}
          className={
            "h-[0.625rem] w-full appearance-none outline-none " +
            sliderClassName
          }
        />
      </div>{" "}
    </div>
  );
}
