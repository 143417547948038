"use client";

import Image from "next/image";
import { AssetId } from "shared/src/SupportedCurrencies";
import { IconsLeaf, assets } from "src/components/SiteIcons";

// contains selector buttons for changing currencies
export function CurrencySelectorDropDown(props: {
  currencies?: readonly AssetId[];
  displayNameOverride?: { [key: string]: string };
  setSelectedCurrency: (currency: AssetId) => void;
  setShowCurrencySelector: (show: boolean) => void;
  showCurrencySelector: boolean;
  selectorButtonId: string;
}) {
  const {
    currencies,
    setSelectedCurrency,
    setShowCurrencySelector,
    showCurrencySelector,
    selectorButtonId,
  } = props;

  const displayNameOverride = props.displayNameOverride ?? {};

  const canShow = currencies && currencies?.length > 1 && showCurrencySelector;

  if (!canShow) {
    return <></>;
  }

  return (
    <div className="absolute top-[2.18rem] z-50 -ml-2 flex w-full flex-col border border-input-border">
      <>
        {currencies.map((currency) => {
          return (
            <CurrencySelectorButton
              key={currency}
              id={selectorButtonId}
              assetIcon={assets[currency]}
              currencyDisplayName={displayNameOverride[currency] ?? currency}
              onClick={() => {
                setSelectedCurrency(currency);
                setShowCurrencySelector(!showCurrencySelector);
              }}
            />
          );
        })}
      </>
    </div>
  );
}

// styles button, changes current currency
type CurrencySelectorButtonProps = {
  assetIcon: IconsLeaf;
  currencyDisplayName: string;
} & JSX.IntrinsicElements["button"];

function CurrencySelectorButton(props: CurrencySelectorButtonProps) {
  const { assetIcon, currencyDisplayName, ...buttonProps } = props;

  return (
    <button
      {...buttonProps}
      className="bg-white p-1 px-2 font-sans text-xs hover:bg-currency"
    >
      <div id="currency-indicator" className="flex flex-row items-center gap-2">
        {" "}
        <Image
          alt={assetIcon.alt}
          width={assetIcon.width}
          height={assetIcon.height}
          src={assetIcon.path}
        />
        <p className="font-sans text-xs font-bold text-input-label">
          {currencyDisplayName}
        </p>
      </div>
    </button>
  );
}
