"use client";

import { useEffect, useState } from "react";
import { CurrencySelectorDropDown } from "./CurrencySelector";
import { AssetId } from "shared/src/SupportedCurrencies";
import Image from "next/image";
import { assets, icons } from "src/components/SiteIcons";

// toggles currency selector dropdown
type ChangeCurrencyButtonProps = {
  setSelectedCurrency: (currency: AssetId) => void;
  selectedCurrency: AssetId;
  currencies?: readonly AssetId[];
  displayNameOverride?: { [key: string]: string };
  deploymentName?: string;
  type: "collateral" | "loan";
};

export function ChangeCurrencyButton(props: ChangeCurrencyButtonProps) {
  const {
    setSelectedCurrency,
    selectedCurrency,
    currencies,
    deploymentName,
    type,
  } = props;

  const displayNameOverride = props.displayNameOverride ?? {};

  const [showCurrencySelector, setShowCurrencySelector] = useState(false);

  const currencyIcon = assets[selectedCurrency];

  const selectorIcon =
    deploymentName === "k33"
      ? icons.currencySelectorButtonBlack
      : icons.currencySelectorButton;

  const changeCurrencyButtonId = type + "-change-currency-button";
  const selectorButtonId = type + "-currency-selector-button";

  // closes selector if click target is anything other than the buttons
  useEffect(() => {
    window.addEventListener("click", (e) => {
      const changeCurrencyButton = document.getElementById(
        changeCurrencyButtonId
      );
      const selectorButton = document.getElementById(selectorButtonId);

      if (e.target !== changeCurrencyButton && e.target !== selectorButton) {
        setShowCurrencySelector(false);
      }
    });
  }, []);

  return (
    <div
      id={changeCurrencyButtonId}
      className="relative flex h-full w-28 min-w-fit flex-row items-center justify-between gap-2 rounded-r-[0.25rem] border-l border-input-border border-opacity-50 bg-currency p-[0.35rem] px-2 hover:cursor-pointer hover:bg-currency-hover"
      onClick={() => setShowCurrencySelector(!showCurrencySelector)}
    >
      <div
        id="currency-indicator"
        className="pointer-events-none flex flex-row items-center gap-2 sm:gap-2"
      >
        <Image
          className="pointer-events-none"
          alt={currencyIcon.alt}
          width={currencyIcon.width}
          height={currencyIcon.height}
          src={currencyIcon.path}
        />
        <label
          id={"currency-name-label"}
          className={
            "pointer-events-none font-sans text-xs font-bold text-input-value"
          }
        >
          {displayNameOverride[selectedCurrency] ?? selectedCurrency}
        </label>
      </div>
      <Image
        className="pointer-events-none"
        alt={selectorIcon.alt}
        width={selectorIcon.width}
        height={selectorIcon.height}
        src={selectorIcon.path}
      />

      <CurrencySelectorDropDown
        selectorButtonId={selectorButtonId}
        currencies={currencies}
        setSelectedCurrency={(currency) => setSelectedCurrency(currency)}
        showCurrencySelector={showCurrencySelector}
        setShowCurrencySelector={(show) => setShowCurrencySelector(show)}
        displayNameOverride={displayNameOverride}
      />
    </div>
  );
}
